<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block" v-show="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-block" v-show="loading === false">
      <div class="modal-header">
        <h3>New Withdrawal Request</h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container pb-2">
          <span class="balance">Available balance: {{ convertToCurrency(wr.agent.balance) }}</span>
          <span class="balance">Withdrawal reserved: {{ convertToCurrency(wr.agent.withdrawalReserved) }}</span>
          <hr>
          <div class="row mb-4">
            <div class="form-group col-md-6">
              <label for="fieldAgent">Agent</label>
              <Multiselect id="fieldAgent"
                           v-model="wr.agent"
                           :allow-empty="false"
                           :class="{selected: wr.agent !== null}"
                           :close-on-select="true"
                           :options="agents"
                           :searchable="true"
                           :show-labels="false"
                           class="full-width"
                           label="name"
                           placeholder="Select agent"
                           track-by="id">
              </Multiselect>
            </div>
            <div class="form-group col-md-6">
              <label for="comp">Amount</label>
              <div class="input-with-icon">
                <span class="icon">$</span>
                <input type="number" class="form-control" id="comp" :disabled="wr.agent.balance <= 0 && !this.isGranted('ROLE_CEO_ASSISTANT') && !this.isGranted('ROLE_CEO')"
                       step="1" min="100.00" v-input-filled:value="wr.amount" v-model="wr.amount">
                <span class="error-msg" v-for="(error, index) in getError('amount', errors)" :key="index">
                  {{ error }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <base-button title="Submit" action="secondary-default" :disabled="!allowSubmitWR" @click-btn="submitWR" :loading="ui.saving" />
        <base-button title="Discard" type="reset" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "NewLender",
  props: { id: { required: false } },
  components: {Multiselect, BaseButton},
  data() {
    return {
      loading: true,
      wr: {
        agent: {},
        amount: 0.00,
      },
      ui: {
        saving: false
      },
      agents: [],
      errors: [],
    }
  },
  methods: {
    getFormData() {
      this.$http.get('/api/v1/wr/get-form-data', this.formData)
        .then((response) => {
          this.agents = response.data.agents
          this.wr.agent = response.data.agent
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => { this.loading = false })
    },
    submitWR() {
      this.ui.saving = true
      const fd = new FormData();
      fd.append('agent', this.wr.agent.id)
      fd.append('amount', this.wr.amount)
      this.$http.post('/api/v1/wr/new', fd)
        .then(() => {
          this.pushAlert('success', 'Withdrawal request successfully submitted')
          this.$emit('close', true)
        })
        .catch()
        .finally(() => this.ui.saving = false)
    },
  },
  computed: {
    allowSubmitWR() {
      return (this.wr.amount > 0 && parseFloat(this.wr.amount) <= parseFloat(this.wr.agent.balance)) || this.isGranted('ROLE_CEO_ASSISTANT') || this.isGranted('ROLE_CEO')
    }
  },
  mounted() {
    this.getFormData()
  }
}
</script>

<style lang="scss" scoped>
.balance {
  display: block;
  font-weight: 500;
  margin-bottom: 2px;
}
.form-switch {
  margin: 0;
  label {
    opacity: 0.46;
    color: rgba(0,0,0,1);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    &.active {
      color: rgba(0,0,0,1);
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      opacity: 1;
    }
  }
  input {
    width: 36px!important;
  }
}
.input-with-icon {
    min-height: 38px;
    height: 38px;
    input {
       min-height: 38px !important;
       height: 38px;
    }
    .icon {
      top: 12px !important;
    }
}
</style>
