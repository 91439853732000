<template>
  <div>
    <!--  Page Header  -->
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Withdrawal Requests
          <span v-if="isActive('active')">
             Click "Process" before doing bank transfer.
          </span>
        </h3>
      </div>
      <div class="d-flex">
        <base-button title="Export" action="secondary" @click-btn="activeExport = true"
                     v-if="isActive('processed') && (isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BOOKKEEPER'))" />
        <base-button title="New Withdrawal Request" action="primary"
                     @click-btn="redirectToNewCRM" v-if="isGranted('ROLE_CEO_ASSISTANT')" class="ms-2" />
      </div>
    </div>

    <!--  Page tabs  -->
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ 'active': isActive('active') }" @click="setActiveTab('active')">
          Active Requests
        </li>
        <li :class="{ 'active': isActive('processed') }" @click="setActiveTab('processed')">
          Processed Requests
        </li>
      </ul>
    </div>

    <div class="page-content p-normal">
      <div class="row mt-2">
        <div class="col-12 col-md-6">
          <div class="search-wrapper">
            <img alt="Icon search" src="@/assets/icons/icon-search.svg">
            <input v-model="searchField" placeholder="Search" type="text" @input="search">
          </div>
        </div>
      </div>
      <div v-if="ui.loading === true" class="text-center mt-5">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-if="!ui.loading && isActive('active')" class="table">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Requested by</th>
            <th>W9 Payee</th>
            <th>CADBO</th>
            <th>Amount</th>
            <th>Submitted at</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="wrActives.length === 0">
            <td colspan="7">
              <div class="col-12 modal-alert modal-alert_advice text-center">
                No Results Found
              </div>
            </td>
          </tr>
          <tr v-for="(wrActive, index) in wrActives" :key="index">
            <td>{{ paging.offset + index + 1 }}</td>
            <td>
              <div class="user-name">
                {{ wrActive.requestedBy }}
              </div>
            </td>
            <td>{{ wrActive.companyPayee }}</td>
            <td>{{ wrActive.cadbo ? 'Yes' : 'No' }}</td>
            <td>
              <div class="user-amount" @click="copyAction(wrActive.id)" :content="wrActive.copyContent"
                v-tippy="{ placement : 'top', arrow : true, arrowType : 'round' }">
                {{ convertToCurrency(wrActive.amount) }}
              </div>
            </td>
            <td>{{ formatDate(wrActive.submittedAt, 'MMM, D Y hh:mm') }}</td>
            <td>
              <div :class="showStatus(wrActive.status)" class="status d-flex justify-content-center withdrawal-status">
                {{ capitalize(wrActive.status) }}
              </div>
            </td>
            <td>
              <div class="d-flex">
                <button v-if="wrActive.isGranted['freeze']" :disabled="wrActive.processing" class="btn text-light btn-primary"
                        @click="freezeRequest(wrActive.id)">
                  <span v-if="wrActive.processing" aria-hidden="true"
                        class="spinner-border spinner-border-sm" role="status">
                  </span>
                  <span v-else>Process</span>
                </button>

                <button v-if="wrActive.isGranted['process']" :disabled="wrActive.completing" class="btn text-light bg-green "
                        @click="processRequest(wrActive.id)">
                  <span v-if="wrActive.completing" aria-hidden="true"
                        class="spinner-border spinner-border-sm" role="status">
                  </span>
                  <span v-else>Complete</span>
                </button>

                <button v-if="wrActive.isGranted['delete']" class="btn btn-control d-inline-flex mx-3" :disabled="wrActive.deleting"
                        @click="deleteRequest(wrActive.id)">
                  <span v-if="wrActive.deleting">...</span>
                  <span v-else><img alt="" src="@/assets/icons/icon-delete.svg"></span>
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div v-if="!ui.loading && isActive('processed')" class="table">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Requested by</th>
            <th>Amount</th>
            <th>Submitted At</th>
            <th>Status</th>
            <th>Processed At</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="wrProcessed.length === 0">
            <td colspan="6">
              <div class="col-12 modal-alert modal-alert_advice text-center">
                No Results Found
              </div>
            </td>
          </tr>
          <tr v-for="(wrProcess, index) in wrProcessed" :key="index">
            <td>{{ paging.offset + index + 1 }}</td>
            <td>{{ wrProcess.requestedBy }}</td>
            <td><b>{{ convertToCurrency(wrProcess.amount) }}</b></td>
            <td>{{ formatDate(wrProcess.submittedAt, 'MMM, D Y hh:mm') }}</td>
            <td>
              <div :class="showStatus(wrProcess.status)"
                   class="status d-flex justify-content-center withdrawal-status processed">
                {{ capitalize(wrProcess.status) }}
              </div>
            </td>
            <td>{{ formatDate(wrProcess.processedAt, 'MMM, D Y hh:mm') }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <Paging :paging="paging" :page="currentPage" @pageChanged="pagingUpdated" @limitChanged="pagingUpdated"></Paging>
    </div>

    <transition name="component-fade" mode="out-in">
      <export-modal
        v-if="activeExport === true"
        @close="activeExport = false"/>
    </transition>

    <transition name="component-fade" mode="out-in">
      <NewWRModal v-if="newWRModal" @close="closeWRModal"/>
    </transition>
  </div>
</template>

<script>
import Paging from "@/components/Paging";
import debounce from "@/utils/debounce";
import ExportModal from "./modals/ExportModal";
import NewWRModal from "@/views/withdrawalRequests/modals/NewWRModal";
import BaseButton from '../../components/BaseButton.vue';

export default {
  name: 'WithdrawalRequest',
  components: { Paging, ExportModal, NewWRModal, BaseButton },
  data() {
    return {
      activeExport: false,
      searchField: '',
      ui: {
        loading: false
      },
      wrActives: [],
      wrProcessed: [],
      activeTab: 'active',
      currentPage: 1,
      paging: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      search: debounce(() => {
        this.paging.offset = 0
        this.getData()
      }, 350),
      newWRModal: false,
    }
  },
  methods: {
    showStatus(status) {
      switch (status.toLowerCase()) {
        case 'pending':
          return "pending";
        case 'frozen':
          return "frozen";
        case 'processed':
          return "processed";
        default:
          return "status_cancelled";
      }
    },
    copyAction(id) {
      let value = 0;
      this.wrActives = this.wrActives.map((wr) => {
        if (wr.id === id) {
          value = wr.amount
        }
        return wr
      })

      try {
        var hiddenInput = document.createElement("input");
        hiddenInput.setAttribute("value", value);
        document.body.appendChild(hiddenInput);
        hiddenInput.select();
        document.execCommand("copy");
        document.body.removeChild(hiddenInput);
        this.wrActives = this.wrActives.map((wr) => {
          if (wr.id === id) {
            wr.copyContent = 'Copied!'
          }
          return wr
        })
      } catch (err) {
        this.wrActives = this.wrActives.map((wr) => {
          if (wr.id === id) {
            wr.copyContent = 'Copied failed!'
          }
          return wr
        })
      }

      setTimeout(() => {
        this.wrActives = this.wrActives.map((wr) => {
          if (wr.id === id) {
            wr.copyContent = 'Click to copy'
          }
          return wr
        })
      }, 1000);
    },
    isActive(tabName) {
      return this.activeTab === tabName
    },
    setActiveTab(tabName) {
      this.activeTab = tabName
      this.searchField = ''
      this.paging.offset = 0
      this.paging.limit = 10
      this.paging.total = 0
      this.currentPage = 1
      this.getData()
    },
    pagingUpdated(paging) {
      this.paging = paging;
      this.currentPage = paging.currentPage
      this.getData();
    },
    closeWRModal(udpateContent) {
      if (udpateContent) {
        this.getData()
      }
      this.newWRModal = false
    },

    // Api Calls
    getData() {
      if (this.isActive('active')) {
        this.getActiveWRData()
      } else {
        this.getProcessedWRData()
      }
    },
    getActiveWRData() {
      this.ui.loading = true
      this.$http.get('/api/v1/wr/active', {
        params: {
          offset: this.paging.offset,
          limit: this.paging.limit,
          query: this.searchField
        }
      })
      .then((res) => {
        this.wrActives = res.data.data
        this.wrActives = this.wrActives.map((wr) => {
          wr.deleting = false
          wr.completing = false
          wr.processing = false
          wr.copyContent = 'Click to copy'
          return wr
        })
        this.paging = res.data.paging
      })
      .catch((err) => {
        this.alertError(err.response.statusText)
      })
      .finally(() => {
        this.ui.loading = false
      })
    },
    getProcessedWRData() {
      this.ui.loading = true
      this.$http.get('/api/v1/wr/processed', {
        params: {
          offset: this.paging.offset,
          limit: this.paging.limit,
          query: this.searchField
        }
      })
      .then((res) => {
        this.wrProcessed = res.data.data
        this.paging = res.data.paging
      })
      .catch((err) => {
        this.alertError(err.response.statusText)
      })
      .finally(() => {
        this.ui.loading = false
      })
    },
    freezeRequest(id) {
      this.wrActives = this.wrActives.map((wr) => {
        if (wr.id === id) {
          wr.processing = true
        }
        return wr
      })

      this.$http.get(`/api/v1/wr/freeze/${id}`)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.getData()
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.wrActives = this.wrActives.map((wr) => {
            if (wr.id === id) {
              wr.processing = false
            }
            return wr
          })
          this.ui.action = false
        })
    },
    processRequest(id) {
      this.wrActives = this.wrActives.map((wr) => {
        if (wr.id === id) {
          wr.completing = true
        }
        return wr
      })

      this.$http.get(`/api/v1/wr/process/${id}`)
        .then((res) => {
          this.alertSuccess(res.data.message)
          this.getData()
        })
        .catch((err) => {
          this.alertError(err.response.statusText)
        })
        .finally(() => {
          this.wrActives = this.wrActives.map((wr) => {
            if (wr.id === id) {
              wr.completing = false
            }
            return wr
          })
          this.ui.action = false
        })
    },
    deleteRequest(id) {
      if (confirm("Are you sure?")) {
        this.wrActives = this.wrActives.map((wr) => {
          if (wr.id === id) {
            wr.deleting = true
          }
          return wr
        })

        this.$http.delete(`/api/v1/wr/delete/${id}`)
          .then((res) => {
            this.alertSuccess(res.data.message)
            this.getData()
          })
          .catch((err) => {
            this.alertError(err.response.statusText)
          })
      }
    },
    redirectToNewCRM() {
      window.open('https://app.clearmortgagecapital.com/withdrawal-requests', '_blank');
    }
  },
  beforeMount() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.status {
  max-width: 95px;
  width: 100%;
  padding: 5px 19px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;

  &_pending {
    border: 1px solid rgba(0, 7, 46, 0.2);
    background-color: rgba(0, 7, 46, 0.05);
    color: black;
  }

  &_frozen {
    border: 1px solid rgba(0, 7, 46, 0.2);
    background-color: white;
    color: black;
  }

  &_paid {
    border: 1px solid rgba(0, 171, 52, 0.4);
    background-color: rgba(0, 171, 52, 0.05);
    color: rgba(0, 171, 52, 1);
  }

  &_cancelled {
    border: 1px solid rgba(210, 10, 59, 0.4);
    background-color: rgba(210, 10, 59, 0.05);
    color: rgba(210, 10, 59, 1);
  }
}

.user-name {
  text-decoration: none;
  width: fit-content;

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background: black;
    transition: all .5s;
  }

  &:hover {
    &:after {
      width: 0;
    }
  }
}

.user-amount {
  width: fit-content;
  color: #000000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 24px;
  border-bottom: 1px dashed #979797;
  cursor: pointer;
  transition: all .3s;
  font-weight: normal;

  &:hover {
    font-weight: 500;
  }
}

.withdrawal-status {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90px;
  width: 100%;
  height: 32px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  border-radius: 8px;
  color: #065AE4;

  &.frozen {
    border: 1px solid rgba(6, 90, 228, 0.4);
    background-color: rgba(38, 111, 229, 0.05)
  }

  &.pending {
    border: 1px solid rgba(0, 7, 46, 0.2);
    background-color: rgba(0, 7, 46, 0.05);
    color: #000000;
  }

  &.processed {
    border: 1px solid rgba(0, 171, 52, 0.4);
    background-color: rgba(0, 171, 52, 0.05);
    color: #00AB34;
  }
}


div.table {
  table.table {
    tbody {
      tr {
        td {
          &:not(:first-child) {
            padding-left: 0;
          }
        }
      }
    }
  }

  button {
    height: 32px;

    &.btn-primary,
    &.bg-green {
      max-width: 110px;
      width: 100%;
    }

    &.btn-control {
      width: 32px;
      height: 32px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

}

.page-header-title {
  span {
    color: #838384;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0;
    margin-left: .4rem;
    font-weight: normal;
  }
}
</style>
