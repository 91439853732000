import { render, staticRenderFns } from "./ExportModal.vue?vue&type=template&id=272a5520&scoped=true&"
import script from "./ExportModal.vue?vue&type=script&lang=js&"
export * from "./ExportModal.vue?vue&type=script&lang=js&"
import style0 from "./ExportModal.vue?vue&type=style&index=0&id=272a5520&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272a5520",
  null
  
)

export default component.exports