<template>
  <div class="modal modal-center" id="export">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block">
      <div class="modal-header">
        <h3>Export in CSV</h3>
      </div>
      <div class="modal-body">
        <div class="text-center mt-4"  v-if="ui.loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-bottom-0" v-else>
          <div class="row">
            <div class="col-md-6">
              <div class="full-width form-group">
                <label class="mb-1">Agents</label>
                <multiselect v-model="selectedAgents"
                             class="full-width"
                             placeholder="Select Agents"
                             :options="agents"
                             :close-on-select="false"
                             :clear-on-select="false"
                             :preserve-search="true"
                             :searchable="true"
                             :multiple="true"
                             :allow-empty="false"
                             :show-labels="false"
                             group-values="values"
                             group-label="allLabel"
                             :group-select="true"
                             track-by="value"
                             label="label">
                              <template slot="selection" slot-scope="{ values }">
                                <span class="multiselect__single" v-if="values.length === 1">
                                  {{ values[0].label }}
                                </span>
                                <span class="multiselect__single" v-if="values.length > 1">
                                  {{ values.length }} agents selected
                                </span>
                              </template>
                </multiselect>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group position-relative full-width">
                <label class="mb-1">Date Range</label>
                <div class="w-100">
                  <date-range-picker
                    ref="picker"
                    v-model="dateRange"
                    class="daterange-dashboard"
                    opens="center"
                    single-date-picker="range"
                    :timePicker="false"
                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }">
                    <template v-slot:input="picker">
                      {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      <img src="@/assets/icons/icon-calender.svg" class="position-absolute" alt="">
                    </template>
                  </date-range-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start"  v-if="!ui.loading">
        <base-button title="Export" action="secondary-default" type="submit" @click-btn="exportFile"
                     :disabled="ui.btnLoading || selectedAgents.length === 0" :loading="ui.btnLoading" />
        <base-button title="Cancel" type="reset" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import Multiselect from 'vue-multiselect';
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: 'ExportModal',
  components: { DateRangePicker, Multiselect, BaseButton },
  data() {
    return {
      ui: {
        loading: false,
        btnLoading: false
      },
      dateRange: {
        startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
      },
      agents: [],
      selectedAgents: []
    }
  },
  methods: {
    exportFile() {
      this.ui.btnLoading = true;
      let formData = {
        startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
        agents: []
      }

      this.selectedAgents.map(function(value) {
        formData.agents.push(value.value)
      })

      this.$http.get(`/api/v1/wr/export`, {params: formData})
      .then((res) => {
        if (res.data) {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let fileName = `WR_${formData.startDate}_${formData.endDate}.csv`
          if (formData.agents.length === 1) {
            fileName = `WR_${this.selectedAgents[0].label.replaceAll(' ', '')}_${formData.startDate}_${formData.endDate}.csv`
          }

          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        }
      })
      .catch((err) => {
        this.alertError(err.response.statusText)
      })
      .finally(() => {
        this.ui.btnLoading = false;
      })
    },
    getForm(){
      this.ui.loading = true;
      this.$http.get('/api/v1/wr/export-form')
      .then((res) => {
        this.agents.push({
          allLabel: 'All Agents',
          values: res.data.agents
        })

        this.selectedAgents.push(res.data.agents[0])
      })
      .finally(() => {
        this.ui.loading = false;
      })
    }
  },
  filters: {
    date(date) {
      return moment(String(date)).format('MMM DD, YYYY')
    }
  },
  created() {
    this.getForm();
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    min-height: 30%;
    height: auto !important;
    margin-top: 5%;
    overflow: unset;
  }
  .modal-body {
    overflow: unset;
  }
  .modal-footer {
    position: sticky;
    z-index: auto;
  }
}

.check-block {
  display: flex;
  align-items: center;
  border: 1px solid rgba(231, 232, 232, 1);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 12px;
  width: fit-content;

  &.active {
    border: 1px solid rgba(1, 122, 255, 1);
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  label {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    margin: 0 0 0 12px;
    white-space: nowrap;
    cursor: pointer;
  }

  .form-check-input {
    height: 18px;
    max-width: 18px;
    margin: 0;
    cursor: pointer;

    &:checked {
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }
}
::v-deep .daterange-dashboard {
  max-width: 100%;
  .reportrange-text {
    line-height: 22px!important;
    max-width: 100%;
  }
  .single.show-ranges .drp-calendar.left {
    border: none!important;
  }
}
::v-deep {
  .multiselect {
    min-height: 36px;
    max-height: 36px;
    min-width: 180px;
    width: 100%;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 8px 40px 0 8px;
      transition: all .3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__content-wrapper {
      max-height: 130px !important;
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}

.daterange-dashboard {
  &::v-deep .daterangepicker {
    min-width: 385px!important;
  }

  &::v-deep {
    .ranges {
      @media (max-width: 568px) {
        widht: 30%;
      }
    }
  }
}
</style>
